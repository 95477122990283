
  
  .finance-game {
    background-color: #f0f2f5;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .taskbar {
    height: 50px;
    background-color: #2e3192;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 10;
  }
  
  .taskbar-start {
    display: flex;
    align-items: center;
  }
  
  .start-button {
    background-color: #ff6b6b;
    color: white;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .app-buttons {
    display: flex;
    gap: 10px;
  }
  
  .app-button {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.1s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .app-button:hover {
    transform: translateY(-2px);
  }
  
  .app-button i {
    font-size: 18px;
    color: #2e3192;
  }
  
  .taskbar-end {
    display: flex;
    align-items: center;
    color: white;
  }
  
  .time-display {
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
  }
  
  .desktop {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
    gap: 20px;
    padding: 30px;
    position: relative;
    height:80vh;
  }
  
  .desktop-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
    width: 100px;
    height: 100px;
  }
  
  .desktop-icon:hover {
    transform: scale(1.05);
  }
  
  .icon-img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
  }
  
  .icon-text {
    font-size: 12px;
    font-weight: bold;
    color: #333;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
  }
  
  .bank {
    background-color: #4caf50;
  }
  
  .invest {
    background-color: #2196f3;
  }
  
  .shop {
    background-color: #ff9800;
  }
  
  .home {
    background-color: #9c27b0;
  }
  
  .job {
    background-color: #f44336;
  }
  
  .education {
    background-color: #009688;
  }
  
  .health {
    background-color: #e91e63;
  }
  
  .window {
    position: absolute;
    width: 80%;
    max-width: 900px;
    height: 80%;
    max-height: 600px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 500000;
  }
  
  .window-header {
    height: 50px;
    background-color: #2e3192;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    color: white;
  }
  
  .window-title {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .window-title-icon {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .window-controls {
    display: flex;
    gap: 10px;
  }
  
  .window-button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .window-minimize {
    background-color: #ffeb3b;
  }
  
  .window-maximize {
    background-color: #4caf50;
  }
  
  .window-close {
    background-color: #f44336;
  }
  
  .window-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  .bank-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .bank-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .balance-card {
    background: linear-gradient(135deg, #4caf50, #2e7d32);
    color: white;
    border-radius: 15px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .balance-title {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .balance-amount {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .card-number {
    font-size: 12px;
    letter-spacing: 2px;
    margin-top: 20px;
  }
  
  .bank-actions {
    display: flex;
    gap: 15px;
    margin-top: 10px;
  }
  
  .bank-action-btn {
    background-color: #f0f2f5;
    border: none;
    border-radius: 8px;
    padding: 10px 15px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .transactions {
    flex: 1;
    margin-top: 30px;
  }
  
  .transactions-title {
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .transaction-list {
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .transaction-item {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid #eeeeee;
  }
  
  .transaction-item:last-child {
    border-bottom: none;
  }
  
  .transaction-info {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .transaction-icon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555;
  }
  
  .transaction-details {
    display: flex;
    flex-direction: column;
  }
  
  .transaction-name {
    font-weight: bold;
    color: #333;
  }
  
  .transaction-date {
    font-size: 12px;
    color: #777;
  }
  
  .transaction-amount {
    font-weight: bold;
  }
  
  .amount-negative {
    color: #f44336;
  }
  
  .amount-positive {
    color: #4caf50;
  }
  
  @media (max-width: 768px) {
    .desktop {
      grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
      gap: 10px;
      padding: 15px;
    }
    
    .desktop-icon {
      width: 80px;
      height: 80px;
    }
    
    .icon-img {
      width: 40px;
      height: 40px;
    }
    
    .window {
      width: 95%;
      height: 85%;
    }
    
    .balance-card {
      width: 100%;
    }
  }
  